var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm no-margin",
          attrs: { title: "LOTO 적용구역" },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" },
              [
                _c("c-textarea", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    rows: 2,
                    name: "lotoApplyArea",
                  },
                  model: {
                    value: _vm.workPermit.lotoApplyArea,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "lotoApplyArea", $$v)
                    },
                    expression: "workPermit.lotoApplyArea",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "LOTO",
            columns: _vm.gridLoto.columns,
            gridHeight: _vm.gridLoto.height,
            data: _vm.workPermit.lotos,
            filtering: false,
            columnSetting: false,
            usePaging: false,
            editable: _vm.editable && _vm.isWriting,
            selection: "multiple",
            rowKey: "sopLotoId",
          },
          on: { linkClick: _vm.linkClick },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && _vm.isWriting
                    ? _c("c-btn", {
                        attrs: { label: "추가", icon: "add" },
                        on: { btnClicked: _vm.addFacility },
                      })
                    : _vm._e(),
                  _vm.editable && _vm.isWriting
                    ? _c("c-btn", {
                        attrs: { label: "제외", icon: "remove" },
                        on: { btnClicked: _vm.removeFacility },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }